import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { Nav, NavItem } from 'reactstrap';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { Link } from 'react-scroll';

import NavBar from 'aulatime/src/components/NavBar';
import AulatimeHeader from 'aulatime/src/components/Header';

import Logo from '../img/logo_nav.png';
const primaryColor = '#003399';

const Header = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                shortcode
              }
            }
          }
        }
        banner01: file(relativePath: { eq: "banner-01.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2625) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        banner02: file(relativePath: { eq: "banner-02.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2625) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        banner03: file(relativePath: { eq: "banner-03.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2625) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const marketingDropDown = (dropdownOpen, toggleDropDown) => (
    <NavItem id="dropdownMarketing">
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} direction="left">
        <DropdownToggle
          tag="a"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
          className="nav-link"
          caret
        >
          Ofertas
        </DropdownToggle>
        <DropdownMenu>
          <a className="flashing" href="/oferta-de-clases-online">Clases online</a>
        </DropdownMenu>
      </Dropdown>
    </NavItem>
  );

  const marketingNav = <Nav navbar>
      <NavItem>
        <a href="/oferta-de-clases-online" className="nav-link flashing">Oferta de clases online</a>
      </NavItem>
    </Nav>

  const nav = (menuClick, offset, dropdownOpen, toggleDropDown) => (
    <Nav navbar>
      {marketingDropDown(dropdownOpen, toggleDropDown)}
      <NavItem>
        <Link href="#" className="nav-link" activeclassname="active" to="bienvenidos" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
          Bienvenidos
        </Link>
      </NavItem>
      <NavItem className="tier-1">
        <Link href="#" className="nav-link" activeclassname="active" to="cursos" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
          Cursos
        </Link>
      </NavItem>
      <NavItem className="tier-2">
        <Link href="#" className="nav-link" activeclassname="active" to="profesionales" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
          Profesionales
        </Link>
      </NavItem>
      <NavItem className="tier-3">
        <Link href="#" className="nav-link" activeclassname="active" to="digital" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
          Online
        </Link>
      </NavItem>
      <NavItem className="tier-2">
        <Link href="#" className="nav-link" activeclassname="active" to="examenes" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
          Exámenes
        </Link>
      </NavItem>
      <NavItem className="tier-1">
        <Link href="#" className="nav-link" activeclassname="active" to="testimonios" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
          Testimonios
        </Link>
      </NavItem>
      <NavItem>
        <Link href="#" className="nav-link" activeclassname="active" to="contacto" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
          Contáctanos
        </Link>
      </NavItem>
    </Nav>
  );

  const navbar = <NavBar
    logo={<img src={Logo} alt="Aula English Time" className="img-fluid" />}
    nav={nav}
    marketingDropDown={marketingDropDown}
    marketingNav={marketingNav}
  />;

  const items = [
    {
      key: '01',
      header:
      <>
        <BackgroundImage
          Tag="div"
          className="bg bg-01"
          fluid={data.banner01.childImageSharp.fluid}
          backgroundColor={primaryColor}
        />
        <div className="hero-unit hero-unit-01 container">
          <div className="row align-items-center">
            <div className="col">
              <h1>Preparación<br/>Exámenes<br/>Cambridge</h1>
            </div>
          </div>
        </div>
      </>
    },
    {
      key: '02',
      header:
      <>
        <BackgroundImage
          Tag="div"
          className="bg bg-02"
          fluid={data.banner02.childImageSharp.fluid}
          backgroundColor={primaryColor}
        />
        <div className="hero-unit hero-unit-02 container">
          <div className="row align-items-center">
            <div className="col">
              <h1>Business English<br/>Inglés de Negocios</h1>
            </div>
          </div>
        </div>
      </>
    },
    {
      key: '03',
      header:
      <>
        <BackgroundImage
          Tag="div"
          className="bg bg-03"
          fluid={data.banner03.childImageSharp.fluid}
          backgroundColor={primaryColor}
        />
        <div className="hero-unit container">
          <div className="row align-items-center">
            <div className="col">
              <h1>Clases<br/>privadas<br/>online</h1>
            </div>
          </div>
        </div>
      </>
    }
  ];

  return (
    <AulatimeHeader
      navbar={navbar}
      items={items}
    />
  );
}

export default Header;
